import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { environmentApi } from 'Shared/lib/Components/WebApi';
import { ServiceCard } from 'Shared/Components';
import Subscription from 'Shared/lib/Components/SubscriptionControl';
import { Spinner } from 'reactstrap';

export default function AccountholderParsing() {
    const { t } = useTranslation(['accountholder_parsing', 'general']);
    const { isReady, client } = environmentApi();
    const [isInitializing, setIsInitializing] = useState(true);
    const [granted, setGranted] = useState(false);

    useEffect(() => {
        if (isReady)
         initialize();
    }, [isReady]);

    async function initialize() {
        await callApi();
        setIsInitializing(false);
    }

    async function callApi(request) {
        const { ok, data } = await client({ method: !!request ? 'POST' : 'GET', path: '/Parsing', responseType: 'json', data: request });
        setGranted(ok && data && data.granted);
    }

    return (
        <ServiceCard title={t('title')}>
            <p>{t('description')}</p>
            {isInitializing ? <><span><Spinner color="primary" size="sm" /> {t('checking')}</span></> : <Subscription subscribed={granted} toggle={() => callApi({ granted: !granted })} />}
        </ServiceCard>);
}
import React from 'react';
import {FormGroup, Label, Placeholder} from 'reactstrap';
import { createContext, useContext } from 'react';

const LoadingContext = createContext(false);

function Lazy({ isLoading, children }) {
    if (isLoading === true)
        return (<Placeholder tag="div" size="lg" color="light" xs="12"/>);

    return (<>{children}</>)
}


export function LazyForm({isLoading, children}){
    return (<LoadingContext.Provider value={isLoading}>
{children}
    </LoadingContext.Provider>);
}

export function LazyFormRow({ label, value, children, helpUrl }) {
    const isLoading = useContext(LoadingContext);
    return (
        <FormGroup>
            {!!label && <Label>{label} {!!helpUrl && <a href={helpUrl} target='_blank'><i class="bi bi-info-circle"></i></a>}</Label>}
            {(!!value || !!children) && <div className='fst-italic text-truncate'>
                <Lazy isLoading={isLoading}>
                    {value || children}
                </Lazy>
            </div>}
        </FormGroup>);
}
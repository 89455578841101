import './App.scss';
import React from 'react';

import App from './Portal/App';
import * as serviceWorker from './registerServiceWorker';
import ReactDOM from 'react-dom/client';
import './i18n';


const container = document.getElementById('root');
var root = ReactDOM.createRoot(container);
root.render(<App />);

serviceWorker.register({
    onUpdate(registration) {
        console.log(
            'New content is available and will be used when all ' +
              'tabs for this page are closed. See https://cra.link/PWA.'
          );

        //console.log('Skip waiting');
        registration.waiting.postMessage({type: 'SKIP_WAITING'});
        //console.log('Skipped waiting, reload');
        //window.location.reload();
        //console.log('reloaded');
    },
    onSuccess(registration){        
        console.log('Content is cached for offline use.');
    }
});
import React from 'react';
import { FormGroup, Label } from 'reactstrap';

export default function Deployments(){
    return (<>
    <FormGroup>
        <Label>Current version</Label>
            <div className="font-italic">4.2.8</div>
    </FormGroup>
    
    <FormGroup>
        <Label>Feature access</Label>
            <div className="font-italic">Early</div>
    </FormGroup>

    </>);
}
import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Feature } from 'Shared/Providers';
import { LazyFormRow } from 'Shared/lib/Components/Lazy';
import { useTranslation } from 'react-i18next';

export default function Configuration({ uniqueName, apiUrl, applicationUserInstalled }) {
    const {t} = useTranslation(['environment','config']);
    const isAuthorized = applicationUserInstalled == true;
    
    return (<>
        <LazyFormRow label={t('labels.unique_name')} value={uniqueName}/>
        <LazyFormRow label={t('labels.api_url')}>{apiUrl}</LazyFormRow>
        <Feature flag="Hosted processing">
            <LazyFormRow label={<>Processing <Badge pill>new</Badge></>}>local <Link>migrate <i className="bi-arrow-right"></i></Link></LazyFormRow>
        </Feature>
        <LazyFormRow label={t('labels.authorization')}>
            {isAuthorized 
                ? <span className="text-success"><i className="bi-check" ></i > {t('authorization.authorized')}</span>
                : <span className="text-warning"><i className="bi-unlock" ></i> {t('authorization.unauthorized')}</span>}
        </LazyFormRow>
                        </>);

}
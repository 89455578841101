
import { Capabilities } from 'Integrations/Components/Components';
import React, { } from 'react';
import { Input } from 'reactstrap';

import { CopyButton, LazyFormRow } from 'Shared/Components';
import DigiCollectLogo from './logo.png';
import { useTranslation } from 'react-i18next';

export default {
    friendlyName: "DigiCollect",
    description: Description,
    url: "https://www.digicollect.nl/",
    logo: DigiCollectLogo,
    type: "Ifunds.Crowdfunding.DigiCollect",
    capabilities: [Capabilities.Donation],
    displayComponent: DigiCollectWebhookDetail,
    addComponent: ApiKeyConnection
}

function Description(){
    const {t} = useTranslation('integrations');
    return (<>{t('digicollect.description')}</>);
}

function DigiCollectWebhookDetail({ data }) {
    const {t} = useTranslation('integrations');

    return (<>
        <p>{t('digicollect.details.part1')}</p>
        <p>{t('digicollect.details.part2')}</p>
        <CopyButton value={data.webhook?.endpoint + '?api_key=' + data.webhook?.apiKey} />
    </>);
}

function ApiKeyConnection({ onChange, details }) {
    const {t} = useTranslation(['integrations']);

    function onChangeApiKey(e) {
        const apiKey = e.target.value;
        onChange({ details: { apiKey: apiKey }, isValid: !!apiKey });
    }

    return (    
    <LazyFormRow label={t('labels.api_key')} helpUrl={t('digicollect.help.apikey')}>
        <Input type="text" className="form-control" value={details?.apiKey} onChange={onChangeApiKey} placeholder={t('labels.api_key')} />
    </LazyFormRow>);
}

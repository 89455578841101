import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import { Nav, NavItem, NavLink, Container } from 'reactstrap';
import { Link, BrowserRouter } from 'react-router-dom';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";

import { FeatureFlagProvider, EnvironmentProvider, Feature, useFeatureFlags } from 'Shared/Providers'

import { PageNotFound } from 'Shared/Components';

import { Home } from './Components/Home';
import Integrations from 'Integrations/App';
import NavMenu from './Components/NavMenu';
import { useTranslation } from 'react-i18next';
import Services from 'Services/App';

const SmartStorage = React.lazy(() => import('SmartStorage/App'));

// MSAL configuration
const configuration = {
    auth: {
        //clientId: "0f06522f-fb73-49dc-aa1a-101697334da5",
        clientId: "9569dd8b-a4f7-4e03-900f-59ec953cb3b5",
        redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URL
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

const pca = new PublicClientApplication(configuration);

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

function NavigationItem({ icon, href, title, onClick }) {
    if (!!href)
        return (<NavItem>
            <NavLink tag={Link} className="text-light" to={href} title={title}>
                <i className={`icon ${icon}`} />
                <span className="label">{title}</span>
            </NavLink>
        </NavItem>);

    return (<NavItem>
        <NavLink tag={Link} to="#" title={title} className="text-light" onClick={onClick}>
            <i className={`icon ${icon}`} />
            <span className="label">{title}</span>
        </NavLink>
    </NavItem>);
}

function AuthContext() {

    useMsalAuthentication(InteractionType.Redirect);


    return (<FeatureFlagProvider>
        <Routes>
            <Route index element={<EnvironmentView />} />
            <Route path=':environmentId/*' element={<EnvironmentView />} />
        </Routes>
    </FeatureFlagProvider>);
}

function EnvironmentView() {
    const [menuOpen, setMenuOpen] = useState(true);
    const navigate = useNavigate();
    const [environment, setEnvironment] = useState();
    const { environmentId } = useParams();
    const { t } = useTranslation('main');
    const { isFeatureEnabled } = useFeatureFlags();

    useEffect(() => {
        if (!!environment && environment.id !== environmentId) {
            navigate(`/${environment.id}`);
        }
    }, [environment, environmentId]);

    function onEnvironmentChange(e) {
        setEnvironment({ id: e.id, onBoardingCompleted: e.onBoardingCompleted });
    }

    return (
        <>
            <NavMenu environment={environment} onEnvironmentChange={(e) => setEnvironment(e)} />
            <EnvironmentProvider environmentId={environment?.id}>
                <AuthenticatedTemplate><div className={menuOpen ? "main" : "main collapsed"}>
                    <div className="sidebar bg-secondary">
                        <Nav vertical className="menu">
                            <NavigationItem href="./" title={t('menu.home')} icon="bi-house" />
                            {!!environment && !!environment.onBoardingCompleted && <>
                                <Feature flag="Smart Storage">
                                    <NavigationItem href="./SmartStorage" title={t('menu.smart_storage')} icon="bi-arrows-angle-contract" />
                                </Feature>
                                <NavigationItem href="./Integrations" title={t('menu.integrations')} icon="bi-arrow-left-right" />
                                <Feature flag="Accountholder Parsing">
                                    <NavigationItem href="./Services" title={t('menu.services')} icon="bi-gear" />
                                </Feature>
                            </>}
                        </Nav>

                        <Nav className="toggle">
                            <NavigationItem onClick={() => setMenuOpen(!menuOpen)} title={menuOpen ? t('menu.show_less') : t('menu.show_more')} icon={menuOpen ? "bi-arrow-bar-left" : "bi-arrow-bar-right"} />
                        </Nav>
                    </div>


                    <Container fluid className="content">
                        <Suspense fallback={<div>Loading...</div>}>
                            <Routes>
                                <Route index element={<Home />} />
                                {isFeatureEnabled("Smart storage") && <Route path='SmartStorage' element={<SmartStorage />} />}
                                <Route path='Crowdfunding/*' element={<Integrations />} />
                                <Route path='Integrations/*' element={<Integrations />} />
                                {isFeatureEnabled("Accountholder Parsing") && <Route path='Services/*' element={<Services />} />}
                                <Route path='*' element={<PageNotFound />} />
                            </Routes>
                        </Suspense>
                    </Container>
                </div>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <p>Please login or signup</p>
                </UnauthenticatedTemplate>
            </EnvironmentProvider>
        </>);
}

export default function App() {
    return (
        <BrowserRouter basename={baseUrl}>
            <MsalProvider instance={pca}>
                <AuthContext />
            </MsalProvider>
        </BrowserRouter>
    );
}

import React, { useContext, useEffect, useState } from 'react';

const FeatureFlagContext = React.createContext({
    enabledFeatures: []
});

export function FeatureFlagProvider({ children }) {
    const [features, setFeatures] = useState([]);
    const [enabledFeatures, setEnabledFeatures] = useState([]);
    const isNonProduction = process.env.NODE_ENV !== 'production';

    function loadFeatures() {
        const source = [
            { name: 'Switch Toggles', isEnabled: true, isPublic: false },

            // Items for public preview
            { name: 'Crowdfunding', isEnabled: true, isPublic: false },
            { name: 'Kentaa', isEnabled: true, isPublic: false },
            { name: 'Cloud 4 Non-Profit', isEnabled: false, isPublic: true },
            { name: 'Connector Sync', isEnabled: false, isPublic: true },
            { name: 'Accountholder Parsing', isEnabled: true, isPublic: false },

            // Items under development
            { name: 'Show Pricing', isEnabled: false, isPublic: isNonProduction },
            { name: 'Hosted processing', isEnabled: false, isPublic: isNonProduction },
            { name: 'Deployments', isEnabled: false, isPublic: isNonProduction },
            { name: 'Smart Storage', isEnabled: false, isPublic: isNonProduction }
        ];

        source.filter(p => p.isPublic).forEach(f => {
            var local = localStorage.getItem(`featureflag[${f.name}]`);
            if (local !== null)
                f.isEnabled = local === "true";
        });

        setFeatures(source);
    }

    function toggleFeature(item) {
        localStorage.setItem(`featureflag[${item.name}]`, !item.isEnabled);
        loadFeatures();
    }

    useEffect(() => {
        loadFeatures()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setEnabledFeatures(features.filter(f => f.isEnabled).map(f => f.name))
    }, [features])

    return (<FeatureFlagContext.Provider value={{
        enabledFeatures: enabledFeatures,
        features: features,
        toggleFeature: toggleFeature
    }}>
        {children}
    </FeatureFlagContext.Provider>);
}


export function useFeatureFlags() {
    const { enabledFeatures, features, toggleFeature } = useContext(FeatureFlagContext);

    function featureControl(f) {
        return {
            isEnabled: f.isEnabled,
            name: f.name,
            toggle: () => toggleFeature(f)
        }
    }

    const publicFeatures = features.filter(f => f.isPublic).map(f => featureControl(f));

    return {
        features: publicFeatures,
        isFeatureEnabled: (flag) => enabledFeatures.includes(flag),
        featureControl: (flag) => publicFeatures.find(f => f.name === flag)
    };
}

export function Feature({ flag, children, disabled }) {
    const { isFeatureEnabled } = useFeatureFlags();

    if (isFeatureEnabled(flag) === !disabled)
        return (<>{children}</>);

    return (<></>);
}

export function FeatureDisabled({ flag, children }) {
    const { isFeatureEnabled } = useFeatureFlags();

    if (isFeatureEnabled(flag))
        return (<></>);

    return (<>{children}</>);
}
import React, { useEffect, useState } from 'react';
import {
    Collapse, Navbar, NavbarBrand, NavbarText, NavbarToggler, NavItem, NavLink,
    UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Spinner,
    Badge
} from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import { WebApi } from 'Shared/Components';

import { Feature, useFeatureFlags } from 'Shared/Providers';

import { SelectDataVerseEnvironment } from './SelectDataVerseEnvironment';
import logo from 'Portal/Images/logo.png';
import { useTranslation } from 'react-i18next';
import { languages } from 'Translations';

function UserAvatar({ user }) {
    // If a user avatar is available, return an img tag with the pic
    if (user.photo) {
        return <img
            src={user.photo} alt="user"
            className="rounded-circle align-self-center mr-2"></img>;
    }

    // No avatar available, return a default icon
    return <i
        className="bi-person-circle rounded-circle align-self-center mr-2"></i>;
}

function LanguageOption({language, flag, name}){
    const { i18n } = useTranslation();
    return <DropdownItem onClick={() => i18n.changeLanguage(language)} className={i18n.language == language ? 'language current' : 'language'}><img src={flag} className="flag" /> {name}</DropdownItem>;
}

function FeatureOption({toggle, name, isEnabled}){
    const { t } = useTranslation('features');
    return <DropdownItem onClick={() => toggle()} key={name}><i className={isEnabled ? "bi-check text-success" : "bi-x"}></i> {t(name)}</DropdownItem>
}

function AuthNavItem({ }) {
    const [profile, setProfile] = useState({});
    const { instance } = useMsal();
    const { account } = WebApi();
    const { features } = useFeatureFlags();
    const { t } = useTranslation('main');

    async function retrieveProfile() {
        const userProfile = await account.get();
        if (userProfile.ok) {
            if (userProfile.data.photo) {
                var image = await account.photo();

                userProfile.data.photo = (window.URL || window.webkitURL).createObjectURL(image.data);
            }
            setProfile(userProfile.data);
        }
    }

    useEffect(() => {
        retrieveProfile();
        // eslint-disable-next-line
    }, [])

    return (<UncontrolledDropdown>
        <DropdownToggle nav className="avatar">
            <UserAvatar user={profile} />
        </DropdownToggle>
        <DropdownMenu end>
            <div className="dropdown-item">
                <h5 className="mb-0">{profile.name}</h5>
                <p className=" text-muted mb-0">{profile.organization}</p>
            </div>
            <DropdownItem divider />
            {languages.map(l => <LanguageOption key={l.key} language={l.key} flag={l.flag} name={l.name} />)}
            <DropdownItem divider />
            {features.length > 0 &&
                <Feature flag="Switch Toggles">
                    <DropdownItem header>Preview</DropdownItem>
                    {features.map(f => (<FeatureOption key={f.name} toggle={() => f.toggle()} name={f.name} isEnabled={f.isEnabled} />))}
                    <DropdownItem divider />
                </Feature>}
            <DropdownItem onClick={() => instance.logout()}>{t('menu.logoff')}</DropdownItem>
        </DropdownMenu>
    </UncontrolledDropdown>);
}

function Environments({ environment, onEnvironmentChange }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [newEnvironmentIsOpen, setNewEnvironmentIsOpen] = useState(false);
    const { environments: environmentsApi, account: accountApi } = WebApi();
    const [environments, setEnvironments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { environmentId } = useParams();
    const { t } = useTranslation('main');

    const toggle = () => setDropdownOpen(prevState => !prevState);

    async function onNewEnvironment(d) {
        setNewEnvironmentIsOpen(false);
        await refreshEnvironments();
        const newEnvironment = environments.find(d.id);
        await onEnvironmentChange(newEnvironment)
    }

    const isAuthenticated = accountApi.isAuthenticated;

    async function refreshEnvironments() {
        var result = await environmentsApi.get();
        if (result.ok)
            setEnvironments(result.data);
    }

    async function loadEnvironments() {
        if (!isAuthenticated)
            return;

        setIsLoading(true);
        await refreshEnvironments();
        setIsLoading(false);
    }

    function changeEnvironment(e) {
        onEnvironmentChange(e);
    }

    useEffect(() => {
        if (!environments || environments.length == 0)
            return;

        if (!environment) {
            if (!!environmentId) {
                const selectedEnvironment = environments.find(s => s.id == environmentId);
                if (!!selectedEnvironment) {
                    changeEnvironment(selectedEnvironment);
                    return;
                }
            }

            changeEnvironment(environments[0]);
        }

    }, [environments]);

    useEffect(() => {
        loadEnvironments();
        // eslint-disable-next-line
    }, [isAuthenticated])

    if (isLoading)
        return (<DropdownItem><Spinner color="primary" size="sm" /> {t('menu.loading_environments')}</DropdownItem>);

    return (<><Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle nav caret className="text-dark">
            <i className="bi-building"></i> {environment?.name}
        </DropdownToggle>
        <DropdownMenu end>
            {environments.map((e, index) => { return (<DropdownItem onClick={() => onEnvironmentChange(e)} key={index}>{e.name} {!e.onBoardingCompleted && <Badge pill color='warning'>{t('menu.attention')}</Badge>}</DropdownItem>); })}
            <DropdownItem divider />
            <DropdownItem onClick={() => { setNewEnvironmentIsOpen(true) }}>{t('menu.connect_new_environment')}</DropdownItem>
        </DropdownMenu>
    </Dropdown>

        <SelectDataVerseEnvironment
            key={newEnvironmentIsOpen}
            isOpen={newEnvironmentIsOpen}
            toggleIsOpen={() => setNewEnvironmentIsOpen(s => !s)}
            onConfirm={d => { onNewEnvironment(d) }}/>
    </>);
}

export default function NavBar({ environment, onEnvironmentChange }) {
    const [collapsed, setCollapsed] = useState();
    const { instance } = useMsal();
    const { t } = useTranslation('main');
    return (
        <header>
            <Navbar expand="sm" color="light" className="navbar-toggleable-sm border-bottom box-shadow mb-3">
                <NavbarBrand tag={Link} to="/"><img src={logo} alt="Ifunds logo" /></NavbarBrand>
                <NavbarText>{t('brand.title')} <i>({process.env.REACT_APP_VERSION})</i></NavbarText>
                <NavbarToggler onClick={() => setCollapsed(!collapsed)} className="mr-2" />
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                    <ul className="navbar-nav flex-grow">
                        <AuthenticatedTemplate>
                            <Environments environment={environment} onEnvironmentChange={onEnvironmentChange} />
                            <AuthNavItem />
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <NavItem>
                                <NavLink onClick={() => instance.loginPopup()}>{t('menu.logon')}</NavLink>
                            </NavItem>
                        </UnauthenticatedTemplate>
                    </ul>
                </Collapse>
            </Navbar>
        </header >
    );
}

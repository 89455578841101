import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from 'reactstrap';

export default function ConfirmationButton({lockTime, onClick, confirmationMessage, outline, color, children}) {
    const [isLocked, setIsLocked] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const {t} = useTranslation('general');

    async function askConfirmation(){
        setIsLocked(true);
        setShowConfirmation(true);
        setTimeout(() => setIsLocked(false), lockTime);
    }

    async function confirm() {
        setIsLocked(true);
        onClick();
        setShowConfirmation(false);
    }

    if (showConfirmation)
        return (
        <div>
            {confirmationMessage}<br />
            <ButtonGroup>
                <Button outline color="primary" id="authorization-error" onClick={() => confirm()} disabled={isLocked}>{t('yes')}</Button >
                <Button color="primary" id="authorization-error" onClick={() => setShowConfirmation(false)}>{t('no')}</Button >
            </ButtonGroup>
        </div>);

return (<>
    <Button outline={outline} color={color} id="authorization-error" onClick={() => askConfirmation()}>{children}</Button >
</>);
}
import React, { } from 'react';
import { Col, Row } from 'reactstrap';

import AccountholderParsing from './AccountholderParsing';
import { Feature } from 'Shared/Providers';

export default function Services() {
    return (<Row>
        <Feature flag="Accountholder Parsing">
            <Col md="6" xl="4" xxl="3">
                <AccountholderParsing />
            </Col>
        </Feature>
    </Row>)
}
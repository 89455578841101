import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Card, Row, Col, CardImg,
    Offcanvas, OffcanvasHeader, OffcanvasBody, CardBody, CardLink, CardSubtitle, CardTitle, CardText, Button
} from 'reactstrap';

import { ExecuteButton } from 'Shared/Components';
import { BadgeOutline, CardFooterButton, CardHeaderLogo, CardSubHeader } from './Components/Components';
import { useTranslation } from 'react-i18next';

export default function ({ onItemSelected, items }) {
    const {t} = useTranslation('general');

    return (<>
        <Row className='mb-1'>
            <Link to='../'>{t('go_back')}</Link>
        </Row>
        <Row className='marketplace'>
            {items.map((item, i) => <Col key={i} sm="6" md="4" xl="3" xxl="2"><MarketplaceListing item={item} onItemSelected={onItemSelected} /></Col>)}
        </Row></>);
}

function MarketplaceListing({ item, onItemSelected }) {
    const [isOpen, setIsOpen] = useState(false);
    const [details, setDetails] = useState({});
    const [isValid, setIsValid] = useState(false);
    const [error, setError] = useState(null);
    const { t } = useTranslation(['integrations', 'general'])

    function toggleIsOpen() {
        setIsOpen(!isOpen);
    }

    function openDialog() {
        setIsValid(false);
        setDetails({});
        setError(null);
        setIsOpen(true);
    }

    function onChange(data) {
        setIsValid(data.isValid);
        setDetails(data.details);
    }

    async function onCommit() {
        try {
            setError(null);
            await onItemSelected({ type: item.type, details: details });
            setIsOpen(false);
        }
        catch (err) {
            setIsValid(false);
            setError(err);
        }
    }

    return (<>
        <Offcanvas isOpen={isOpen} direction='end' toggle={toggleIsOpen}>
            <OffcanvasHeader toggle={toggleIsOpen}>
                <CardImg top width="100%" src={item.logo} alt={item.friendlyName} />
            </OffcanvasHeader>
            <OffcanvasBody>
                <CardSubtitle><item.description /></CardSubtitle>
                <Link to={item.url}>{t('marketplace.visit_website')}</Link>
                <hr />
                <CardTitle tag="h5">{t('marketplace.setup_header', {name: item.friendlyName})}</CardTitle>
                <form autoComplete='off'>
                    <item.addComponent details={details} isValid={isValid} onChange={d => onChange(d)} />
                </form>
                {!!error && (<p>{t('error', {message: error, ns: 'general'})}</p>)}
                <ExecuteButton color='primary' onClick={() => onCommit()} disabled={!isValid}>{t('marketplace.connect')}</ExecuteButton>
            </OffcanvasBody>
        </Offcanvas>
        <Card>
            <CardHeaderLogo>
                <CardImg src={item.logo} alt={item.friendlyName} />
            </CardHeaderLogo>
            <CardSubHeader>
                {item.capabilities.map(c => (<BadgeOutline pill color="primary" className='badge-outline'>{t(`capability.${c.label}`, {defaultValue: c.label})}</BadgeOutline>))}
            </CardSubHeader>
            <CardBody>
                <CardText>
                    <item.description />
                </CardText>
                <CardLink href={item.url} target="_blank">{t('marketplace.visit_website')} <i class="bi bi-box-arrow-up-right"></i></CardLink>
            </CardBody>
            <CardFooterButton>
                <Button color='primary' outline  onClick={() => openDialog()}>{t('marketplace.get_it_now')}</Button>
            </CardFooterButton>
        </Card>
    </>);
}
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, CardImg, Button, Offcanvas, OffcanvasHeader, OffcanvasBody, CardText, CardBody } from 'reactstrap';
import { Route, Routes, useNavigate } from 'react-router';

import { Feature, useEnvironments } from 'Shared/Providers';
import { PageNotFound, ConfirmationButton, LazyFormRow } from 'Shared/Components';

import { useApi } from './Api';
import connectorTypes from 'Integrations/Connectors'
import MarketPlace from './MarketPlace'
import { Link } from 'react-router-dom';
import { BadgeOutline, CardFooterButton, CardHeaderLogo } from './Components/Components';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

function ConnectorCard({ connectorType, data, onDelete, onSync, onSetAsDefault }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const {t} = useTranslation(['integrations', 'general']);

    function toggleExpanded() {
        setIsExpanded(!isExpanded);
    }

    return (<>
        <Offcanvas isOpen={isExpanded} toggle={toggleExpanded} direction="end">
            <OffcanvasHeader toggle={toggleExpanded} >
                <CardImg top width="100%" src={connectorType.logo} alt={connectorType.friendlyName} />
            </OffcanvasHeader>
            <OffcanvasBody>
                <CardText><connectorType.description /></CardText>
                <hr />
                <GeneralDetails {...data} />
                <hr />
                {!!connectorType.displayComponent && <><connectorType.displayComponent data={data} />
                <hr /></>}                
                <LazyFormRow>
                    <ConfirmationButton color="link" onClick={() => onDelete(data.id)} confirmationMessage={t('connector.delete_confirmation')}>{t('delete', {ns: 'general'})}</ConfirmationButton>
                </LazyFormRow>
                <Feature flag="Connector Sync">
                    <LazyFormRow>
                        <ConfirmationButton color="link" onClick={() => onSync(data.id)} confirmationMessage={t('connector.sync_confirmation')}>{t('connector.sync')}</ConfirmationButton>
                    </LazyFormRow>
                </Feature>
            </OffcanvasBody>
        </Offcanvas>

        <Card outline>
            <CardHeaderLogo>
                <CardImg src={connectorType.logo} alt={connectorType.friendlyName} />
            </CardHeaderLogo>
            <CardBody>
                <CardText>{connectorType.capabilities.map((c,index) => (<Capability key={index} value={c} data={data} setAsDefault={(id, forType) => onSetAsDefault(id, forType)} />))}</CardText>
                <GeneralDetails {...data} />
            </CardBody>
            <CardFooterButton>
                <Button color='primary' outline onClick={toggleExpanded}>{t('connector.show_details')}</Button>
            </CardFooterButton>
        </Card>
    </>);
}

function Capability({value,data, setAsDefault, className}){
    const hasDefault = !!value.default;
    const isDefault = value.default && data.defaults[value.default] === true;

    const classes = classNames(className, 'capability', { 'capability-has-default': hasDefault, 'capability-is-default': isDefault})

    const {t} = useTranslation('integrations');

    return (
    <BadgeOutline pill color="primary" className='badge-outline'>
        {t(`capability.${value.label}`, {defaultValue: value.label})} <span className={classes}>
            <i className="bi bi-bookmark-check"></i><a onClick={() => setAsDefault(data.id, value.default)} className='details'>{t('connector.set_as_default')}</a>
            </span>
    </BadgeOutline>);
}

function GeneralDetails({ name, description, url }) {
    return (<>
        <h6>{name}</h6>
        <p>{description}</p>
        <a href={url} target="_blank">{url}</a>
    </>);
}

function Connectors({ items, onDelete, onSync, onSetAsDefault }) {
    const {t} = useTranslation('integrations');

    return (<>
        <Row className='mb-1'>
            <Link to='Market'>{t('overview.add_integration')}</Link>
        </Row>
        <Row className="connectors">
            {items
                .map((i, index) => {
                    return (
                        <Col key={index} sm="6" md="4" xl="3" xxl="2">
                            <ConnectorCard connectorType={i.card} data={i.data} onDelete={id => onDelete(id)} onSync={id => onSync(id)} onSetAsDefault={onSetAsDefault} />
                        </Col>)
                })}
        </Row></>);
}

function Integrations() {
    const [config, setConfig] = useState({ ok: true, items: [] });
    const [isLoading, setIsLoading] = useState(false);
    const { environmentId } = useEnvironments();
    const connectorApi = useApi(environmentId);
    const navigate = useNavigate();
    const {t} = useTranslation('integrations');

    useEffect(() => {
        if (environmentId)
            loadConfiguration();
        // eslint-disable-next-line
    }, [environmentId]);

    async function deleteConnector(id) {
        var result = await connectorApi.disconnect(id);
        if (result.ok)
            await refreshItems();
    }

    async function refreshItems() {
        var result = await connectorApi.list();

        if (result.ok)
            setConfig({ ok: result.ok, items: result.data, message: '' })
        else
            setConfig({ ok: result.ok, items: [], message: result.data.message });
    }

    async function loadConfiguration() {
        setIsLoading(true);
        await refreshItems();
        setIsLoading(false);
    }

    async function onAddConnection(connector) {
        var result = await connectorApi.connect(connector);
        if (result.ok) {
            await refreshItems();
            navigate('./');
        }
        else
            throw result.data.message;
    }

    async function onSetAsDefault(id, forType){
        var result = await connectorApi.setAsDefault(id, forType);
        if (result.ok)
            await refreshItems();
    }

    if (!environmentId)
        return (<PageNotFound />);

    if (isLoading)
        return (<div>{t('overview.loading_integrations')}</div>);

    return (
        <Routes>
            <Route exact path='' element={<ShowConnectors
                data={config}
                onDelete={id => deleteConnector(id)}
                onSync={id => connectorApi.sync(id)} 
                onSetAsDefault={onSetAsDefault}/>} />
            <Route path='Market' element={<MarketPlace items={connectorTypes.filter(i => i.canAdd !== false)} onItemSelected={onAddConnection} />} />
            <Route element={<div>Not found</div>} />
        </Routes>);
}

function ShowConnectors({ data, onDelete, onSync, onSetAsDefault }) {

    if (!data.ok)
        return (<ShowError message={data.message} />);

    const connectorsByType = connectorTypes.reduce((current, a) => ({ ...current, [a.type]: a }), {});
    const items = data.items.map((i) => ({ data: i, card: connectorsByType[i.type] })).filter(d => !!d.card);
    return (<Connectors items={items} onDelete={onDelete} onSync={onSync} onSetAsDefault={onSetAsDefault} />);
}

function ShowError({ message }) {
    const {t} = useTranslation('general');

    return (t('error', {message: message}));
}

export default Integrations;
import { Capabilities } from 'Integrations/Components/Components';
import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import fundizLogo from './logo.png'
import { useTranslation } from 'react-i18next';

export default {
    friendlyName: "Fundiz",
    description: Description,
    url: "https://www.fundiz.com/",
    logo: fundizLogo,
    type: "Ifunds.Connector.Fundiz",
    capabilities: [Capabilities.DonationLink, Capabilities.Donation],
    addComponent: Add
}

function Description(){
    const {t} = useTranslation('integrations');
    return (t('fundiz.description'));
}

function Add(props){
    const {onChange} = props;

    const [apiKey, setApiKey] = useState();
    const [websiteKey, setWebsiteKey] = useState();
    const [friendlyName, setFriendlyName] = useState();
    const {t} = useTranslation('integrations');

useEffect(() => {
    onChange({
        details: {
            apiKey: apiKey, 
            websiteKey: websiteKey, 
            friendlyName: friendlyName
    },
    isValid: !!apiKey && !!websiteKey
});
// eslint-disable-next-line 
}, [apiKey, websiteKey, friendlyName])

    return (<>
        <FormGroup>
            <Label htmlFor="friendly-name">{t('labels.friendly_name')}</Label>
            <Input type="text" name='friendly-name' className="form-control" value={friendlyName} onChange={e => setFriendlyName(e.target.value)} placeholder={t('labels.friendly_name')} />
        </FormGroup>
            <FormGroup>
                <Label htmlFor="website-key">{t('fundiz.labels.website_key')}</Label>
                <Input type="text" name='website-key' className="form-Control" value={websiteKey} onChange={e => setWebsiteKey(e.target.value)} placeholder={t('fundiz.labels.website_key')} />
            </FormGroup>
    <FormGroup>
        <Label htmlFor="api-key">{t('labels.api_key')}</Label>
        <Input type="password" name='api-key' autoComplete='new-password' className="form-control" value={apiKey} onChange={e => setApiKey(e.target.value)} placeholder={t('labels.api_key')} />
    </FormGroup></>)
}
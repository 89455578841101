import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Offcanvas, OffcanvasHeader, OffcanvasBody, Input } from 'reactstrap';
import { LazyForm, LazyFormRow, WebApi } from 'Shared/Components';
import { Feature } from 'Shared/lib/Providers/FeatureFlagContext';

export function SelectDataVerseEnvironment({ isOpen, onConfirm, toggleIsOpen }) {
    const [availableEnvironments, setAvailableEnvironments] = useState([]);
    const [onboarding, setOnboarding] = useState(false);
    const [error, setError] = useState();
    const { t } = useTranslation(['environment', 'general']);
    const products = [
        { name: 'Ifunds.Engage365', friendlyName: t('onboarding.products.engage365') },
        { name: 'Ifunds.CloudForNonprofit', friendlyName: t('onboarding.products.c4np') }
    ];
    const [environment, setEnvironment] = useState(-1);
    const [product, setProduct] = useState(products[0].name);

    const { dataVerse } = WebApi();

    async function loadEnvironments() {
        var result = await dataVerse.discover();
        if (result.ok) {
            setAvailableEnvironments(result.data);
        }
    }

    useEffect(() => {
        if (isOpen)
            loadEnvironments();
        // eslint-disable-next-line
    }, [isOpen]);

    async function onBoard(data) {
        setError(null);
        setOnboarding(true);

        var result = await dataVerse.configure(data.id, { "environmentType": product });

        setOnboarding(false);

        if (result.ok) 
            onConfirm(data);
        
        if (!result.ok) {
            const errors = [`onboarding.error.${result.status}`, 'onboarding.error.generic'];
            if (!!result.data?.reason)
                errors.unshift(`onboarding.error.${result.data.reason}`);

            setError(t(errors));
        }
    }

    function renderError() {
        return (<>{!!error && <div className="alert alert-warning" role="alert">
            {t('error', { message: error, ns: 'general' })}
        </div>}</>);
    }

    return (
        <Offcanvas
            isOpen={isOpen}
            direction='end'
            toggle={toggleIsOpen}
        >
            <OffcanvasHeader toggle={toggleIsOpen}>
                {t('onboarding.title')}
            </OffcanvasHeader>
            <OffcanvasBody>
                <LazyForm>
                    <LazyFormRow label={t('onboarding.environment')}>
                        <Input  name="select-dataverse" type='select' value={environment} onChange={e => setEnvironment(e.target.value)}>
                            <option value="-1" hidden disabled>{t('onboarding.select_environment')}</option>
                            {availableEnvironments.map((inst, index) => <option value={index} key={index}>{inst.friendlyName}</option>)}
                        </Input>
                    </LazyFormRow>
                    <Feature flag='Cloud 4 Non-Profit'>
                        <LazyFormRow label={t('onboarding.product')}>
                            <Input name='select-type' type="select" value={product} onChange={e => setProduct(e.target.value)}>
                                {products.map(((item, index) => <option value={item.name} key={index}>{item.friendlyName}</option>))}
                            </Input>
                        </LazyFormRow>
                    </Feature>
                    </LazyForm>

                {renderError()}

                <Button color="primary" disabled={onboarding} onClick={() => onBoard(availableEnvironments[environment])}>{t('create', { ns: 'general' })}</Button>
            </OffcanvasBody>
        </Offcanvas>);
}
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {translations} from 'Translations';

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        react: {
            useSuspense: false
        },
        fallbackLng: 'en',
        compatibilityJSON: 'v3',
        keySeperator: '.',
        debug: process.env.NODE_ENV !== 'production',
        interpolation: {
            escapeValue: false
        },
        resources: translations
    });

export default i18next;

import { Capabilities } from 'Integrations/Components/Components';
import React, { useEffect, useState } from 'react';
import { Button, Input } from 'reactstrap';

import { CopyButton, LazyFormRow } from 'Shared/Components';
import Logo from './logo.png';
import { useTranslation } from 'react-i18next';

export default {
    friendlyName: "Periodiek Schenken",
    description: Description,
    url: "https://www.periodiekschenken.nl/",
    logo: Logo,
    type: "Ifunds.Seneca.PeriodiekSchenken",
    capabilities: [Capabilities.DonationAgreement],
    displayComponent: Display,
    addComponent: Onboard
}

function Description() {
    const { t } = useTranslation('integrations');
    return (<>{t('periodiekschenken.description')}</>);
}

function Display({ data }) {
    const { t } = useTranslation('integrations');

    const instructions = `Wij willen graag gebruik maken van de real-time koppeling met Ifunds, zouden jullie daarvoor de volgende koppelingen in willen stellen?

Pre-Process koppeling (Web Push): 
    ${data.webhook.endpoint}?stage=draft&key=${data.webhook.apiKey}

Product koppeling (Web Push): 
    ${data.webhook.endpoint}?stage=final&key=${data.webhook.apiKey}
    
Voor beide koppelingen is het voldoende om alleen het id van het ingestuurde formulier mee te sturen, alle andere opties mogen uit staan.`;

const email = `Beste ,
    
${instructions}
`;
const emailLink = `mailto:support@seneca.nl&subject=${encodeURIComponent('Ifunds koppeling toevoegen')}&body=${encodeURIComponent(email)}`;
    return (<>
        <p>{t('periodiekschenken.setup')}</p>
        <p><CopyButton value={instructions}>Instructies</CopyButton> <Button outline color="primary" onClick={() => { window.location = emailLink }}>{t('periodiekschenken.sendmail')} <i className="bi-envelope-at" /></Button></p>
    </>);
}

function Onboard({ onChange }) {
    const { t } = useTranslation('integrations');

    const [friendlyName, setFriendlyName] = useState("Periodiek Schenken");
    const [apiKey, setApiKey] = useState();
    const [apiSecret, setApiSecret] = useState();
    const [url, setUrl] = useState();

    useEffect(() => {
        onChange({
            details: {
                friendlyName: friendlyName,
                baseUrl: url,
                apiKey: apiKey,
                apiSecret: apiSecret
            },
            isValid: !!friendlyName && !!apiKey && !!apiSecret && !!url
        });
    }, [friendlyName, apiKey, apiSecret, url])

    return (
        <>
            <LazyFormRow label={t('labels.friendly_name')}>
                <Input type="text" className="form-control" value={friendlyName} onChange={e => setFriendlyName(e.target.value)} placeholder={t('labels.friendly_name')} />
            </LazyFormRow>
            <LazyFormRow label={t('periodiekschenken.labels.api_key')}>
                <Input type="text" className="form-control" value={apiKey} onChange={e => setApiKey(e.target.value)} placeholder={t('periodiekschenken.labels.api_key')} />
            </LazyFormRow>
            <LazyFormRow label={t('periodiekschenken.labels.api_secret')}>
                <Input type="text" className="form-control" value={apiSecret} onChange={e => setApiSecret(e.target.value)} placeholder={t('periodiekschenken.labels.api_secret')} />
            </LazyFormRow>
            <LazyFormRow label={t('periodiekschenken.labels.url')}>
                <Input type="url" className="form-control" value={url} onChange={e => setUrl(e.target.value)} placeholder={t('periodiekschenken.labels.url_placeholder')} />
            </LazyFormRow>
        </>);
}

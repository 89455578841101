import React, { useState } from 'react';
import { Card, CardTitle, CardSubtitle, DropdownMenu, DropdownItem, Dropdown, DropdownToggle } from 'reactstrap';

export default function ServiceCard ({title, subtitle, needsAttention, children, menu}) {
    return (
        <Card body outline color={needsAttention ? "warning" : ""}>
            <div style={{display: 'flex' , flexWrap: 'nowrap'}}>
            <CardTitle tag="h5" style={{flexGrow:'1'}}>{title}</CardTitle>
 {menu && <CardMenu items={menu} />}
            </div>
            <CardSubtitle tag="h6" className="mb-2 text-muted">{subtitle}</CardSubtitle>

            <hr />
            {children}
        </Card>);
}

function CardMenu({items}){
    const [isOpen, setIsOpen] = useState(false);

    function toggle(){
        setIsOpen(p => !p);
    }

    return (
    <Dropdown isOpen={isOpen} toggle={toggle} direction='down'>
        <DropdownToggle color="link"><i className="bi-three-dots" /></DropdownToggle>
        <DropdownMenu end>
            {items.map((item, i) => (<CardMenuItem key={i} name={item.name} onClick={item.action} />))}            
        </DropdownMenu>
        </Dropdown>);
}

function CardMenuItem({name, onClick, ...args}){
    const [isExecuting, setIsExecuting] = useState(false);

    async function execute(){
        setIsExecuting(true);

        await onClick();

        setIsExecuting(false);
    }

    return (<DropdownItem onClick={execute} disabled={isExecuting} {...args}>{name}</DropdownItem>);
}
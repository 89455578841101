import { WebApi } from 'Shared/Components';

export function useApi(environment) {
    const { callWebApiForUser: apiClient } = WebApi();

    async function callWebApiForUser(request) {
        if (!environment) {
            return {
                status: 400,
                statusText: "No environment selected",
                ok: false,
            }
        }

        return await apiClient({
            method: request.method || 'POST',
            path: `/${environment}/Connector${request.path}`,
            data: request.data,
            responseType: request.responseType
        });
    }

    return {
        list: () => callWebApiForUser({ method: 'GET', path: `/`, responseType: 'json' }),
        connect: (data) => callWebApiForUser({ method: 'POST', path: `/`, responseType: 'json', data: data }),
        sync: (id) => callWebApiForUser({ method: 'POST', path: `/${id}/sync`, responseType: 'json' }),
        disconnect: (id) => callWebApiForUser({ method: 'DELETE', path: `/${id}`, responseType: 'json' }),
        setAsDefault: (id, forType) => callWebApiForUser({ method: 'POST', path: `/${id}/set_as_default`, responseType: 'json', data: {for: forType}})
    };
}
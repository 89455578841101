import React, { useContext, createContext } from 'react';

export const EnvironmentContext = createContext({ environmentId: false});

export function useEnvironments() {
    return useContext(EnvironmentContext);
}

export function EnvironmentProvider({environmentId, children}) {
    return (<EnvironmentContext.Provider value={{environmentId: environmentId}}>
        {children}
    </EnvironmentContext.Provider>);
}
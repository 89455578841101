import { WebApi } from 'Shared/Components';

export function useApi(environment) {
    const { callWebApiForUser: apiClient } = WebApi();

    async function callWebApiForUser(request) {
        if (!environment) {
            return {
                status: 400,
                statusText: "No environment selected",
                ok: false,
            }
        }

        return await apiClient({
            method: request.method || 'POST',
            path: `/${environment}/v2/DataVerse${request.path}`,
            data: request.data,
            responseType: request.responseType
        });
    }

    return {
        get: () => callWebApiForUser({ method: 'GET', path: `/`, responseType: 'json' }),
        refreshConfig: () => callWebApiForUser({ method: 'POST', path: '/RefreshConfig'}),
        authorize: () => callWebApiForUser({ path: `/ReAuthorize` }),
    };
}
import React, { useState } from 'react';
import { Button, Spinner } from 'reactstrap';

export default function ExecuteButton(props) {
    const [isExecuting, setIsExecuting] = useState(false);

    async function onClick() {
        setIsExecuting(true);
        var result = await props.onClick();
        setIsExecuting(false);
        return result;
    }

    if (isExecuting)
        return (<Button {...props} disabled><Spinner color="warning" size="sm" /> {props.children}</Button >);

    return (<Button {...props} onClick={() => onClick()}>{props.children}</Button>);
}
import React, { useState, useEffect } from 'react';

import { useEnvironments } from 'Shared/Providers';
import { ServiceCard } from 'Shared/Components';

import { useApi } from './Providers/Api'
import Configuration from './Components/Configuration';
import { LazyForm, LazyFormRow } from 'Shared/Components';
import { useTranslation } from 'react-i18next';

export function PlatformCard(props) {
    const [config, setConfig] = useState({
        isComplete: false
    });
    const [isLoading, setIsLoading] = useState(false);
    const { environmentId } = useEnvironments();
    const dataVerse = useApi(environmentId);
    const {t} = useTranslation('environment');
    const menu = [
        {name: t('card.refresh_config'), action: () => dataVerse.refreshConfig()},
        {name: t('card.re_authorize'), action: () => onAuthorize()}
    ];

    useEffect(() => {
        if (environmentId)
            loadConfiguration();
        // eslint-disable-next-line
    }, [environmentId]);

    async function loadConfiguration() {
        setIsLoading(true);

        var result = await dataVerse.get();
        if (result.ok)
            setConfig(result.data);

        setIsLoading(false);
    }

    async function onAuthorize() {
        const authorizationResult = await dataVerse.authorize();

        var result = await dataVerse.get();
        if (result.ok)
            setConfig(result.data);

        return authorizationResult;
    }

    return (<ServiceCard title={t('card.title')} subtitle={t('card.subtitle')} needsAttention={!config.isComplete} menu={menu}>
        <LazyForm isLoading={isLoading}>
            <LazyFormRow label={t('labels.type')} value="Engage 365"/>
            <LazyFormRow label={t('labels.status')}>
                <Status type={config.isComplete ? "success" : "warning"} success={t('status.success')} warning={t('status.needs_attention')} />
            </LazyFormRow>
            <Configuration onAuthorize={onAuthorize} {...config} t={t}/>
        </LazyForm>
    </ServiceCard>);

}

function Status({type, success, warning, danger}){
    switch (type) {
        case "success":
            return (<span className="text-success"><i className="bi-check" ></i > {success}</span> );
        case "warning":
            return (<span className="text-warning"><i className="bi-exclamation-diamond" ></i > {warning}</span> );
            case "danger":
                return (<span className="text-danger"><i className="bi-x-octagon" ></i > {danger}</span> );
        default:
            return (<></>);
    }
}

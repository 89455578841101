import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

export default function CopyButton({value}) {
    const [copied, setCopied] = useState(false);
    const {t} = useTranslation('general');
    async function copy() {
        navigator.clipboard.writeText(value);
        setCopied(true);

        setTimeout(() => { setCopied(false); }, 1000);
    }

    return (<Button outline color={copied ? "success" : "primary"} onClick={() => { copy(); }}>{t('copy')} <i className={copied ? "bi-clipboard-check" : "bi-clipboard"} /></Button>);
}
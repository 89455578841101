import { PlatformCard } from 'DataVerse/App';
import Deployments from 'DataVerse/Components/Deployments';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import { PreviewTag, ServiceCard } from 'Shared/Components';
import { Feature } from 'Shared/Providers';

export function Home() {
  const { t } = useTranslation(['home', 'main']);

  return (<>
    <Row>
      <Col xs="6" md="4" lg="3">
        <ServiceCard title={t('intro.title')} subtitle={t('intro.subtitle')}>
          <p>{t('intro.content')}</p>
          <ul>
            <Feature flag="Smart Storage"><li><Link to="SmartStorage">{t('menu.smart_storage', { ns: 'main' })}</Link></li></Feature>
            <li><Link to="Integrations">{t('menu.integrations', { ns: 'main' })}</Link></li>
            <Feature flag="Hosted processing"><li><Link to="DataVerse">Saas processing</Link> <PreviewTag flag="Hosted processing" /> </li></Feature>
            <Feature flag="Accountholder Parsing"><li><Link to="Services">{t('menu.services', { ns: 'main' })}</Link></li></Feature>
          </ul>
        </ServiceCard>
      </Col>
      <Col xs="6" md="4" lg="3">
        <PlatformCard />
      </Col>
      <Feature flag="Deployments">
        <Col xs="6" md="4" lg="3">
          <ServiceCard title="Updates" subtitle={<>Configure update cadence  <br /><PreviewTag flag="Deployments" /></>}>
            <Deployments />
          </ServiceCard>
        </Col>
      </Feature>
    </Row>
  </>
  );
}
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';

export default function AuthorizeButton({ onAuthorize, isAuthorized }) {
    const [isAuthorizing, setIsAuthorizing] = useState(false);
    const [error, setError] = useState();
    const {t} = useTranslation('buttons');

    async function authorize() {
        setIsAuthorizing(true);
        var result = await onAuthorize();
        if (!result.ok) {
            setError("Something went wrong");
        }
        setIsAuthorizing(false);
    }

    if (isAuthorized === true)
        return (<span className="text-success"><i className="bi-check" ></i > {t('authorize.authorized')}</span>);

    if (isAuthorizing)
        return (<Button outline color="primary" id="authorization-error" disabled>{t('authorize.authorizing')} <Spinner color="warning" size="sm" /></Button >);

    return (<>
        <Button outline color="primary" id="authorization-error" onClick={() => authorize()}>{t('authorize.authorize')} <i className="bi-unlock" ></i></Button >

        <Popover isOpen={!!error} target="authorization-error">
            <PopoverHeader>{t('authorize.failure')}</PopoverHeader>
            <PopoverBody>
                {error}
            </PopoverBody>
        </Popover>
    </>);
}

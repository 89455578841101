import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Input, Label } from 'reactstrap';

export default function Subscription({subscribed, toggle}){
    const { t } = useTranslation(['general']);
    const [isToggling, setIsToggling] = useState(false);

    async function executeToggle(){
        try {
            setIsToggling(true);
            await toggle();        
        }
        finally{
            setIsToggling(false);
        }
    }

    return (<FormGroup switch>
        <Label check>{subscribed ? t('subscribed') : t('subscribe')}</Label>
        <Input type="switch" role="switch" checked={subscribed} onChange={() => executeToggle()} disabled={isToggling} />
    </FormGroup>);
}
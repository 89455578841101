import { Capabilities } from 'Integrations/Components/Components';
import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Input, ButtonGroup, Button } from 'reactstrap';
import logo from './logo.svg'
import { useTranslation } from 'react-i18next';

export default {
    friendlyName: "Tikkie",
    description: Description,
    url: "https://www.tikkie.me/zakelijk",
    logo: logo,
    type: "Ifunds.Connector.Tikkie",
    capabilities: [ Capabilities.DonationLink, Capabilities.Donation ],
    addComponent: Add
}

function Description(){
    const {t} = useTranslation('integrations');

    return (t('tikkie.description'));
}

function Add(props){
    const {onChange} = props;

    const [isSandBox, setIsSandBox] = useState(true);
    const [appToken, setAppToken] = useState();
    const [friendlyName, setFriendlyName] = useState();
    const { t } = useTranslation('integrations');

useEffect(() => {    
    onChange({
        details: {
            appToken: !isSandBox ? appToken : null, 
            friendlyName: friendlyName,
            tikkieType: isSandBox ? "Sandbox" : "Production"
    },
    isValid: !!friendlyName && (isSandBox || !!appToken)
});
}, [appToken, friendlyName, isSandBox])

    return (<>
        <FormGroup>
            <Label htmlFor="friendly-name">{t('labels.friendly_name')}</Label>
            <Input type="text" name='friendly-name' className="form-control" value={friendlyName} onChange={e => setFriendlyName(e.target.value)} placeholder={t('labels.friendly_name')} />
        </FormGroup>
    <FormGroup>
        <ButtonGroup name="tikkie-type">
            <Button color="primary" outline onClick={() => setIsSandBox(true)} active={isSandBox}>{t('tikkie.labels.sandbox')}</Button>
            <Button color="primary" outline onClick={() => setIsSandBox(false)} active={!isSandBox}>{t('tikkie.labels.production')}</Button>
        </ButtonGroup>
    </FormGroup>
            <FormGroup hidden={isSandBox}>
                <Label htmlFor="app-token">{t('tikkie.labels.app_token')}</Label>
                <Input type="text" name='app-token' className="form-Control" value={appToken} onChange={e => setAppToken(e.target.value)} placeholder={t('tikkie.labels.app_token')} />
            </FormGroup></>)
}
import React from 'react';
import { Badge } from 'reactstrap';

import { useFeatureFlags } from 'Shared/Providers';

export default function PreviewTag({flag}) {
    const { featureControl } = useFeatureFlags();
    const feature = featureControl(flag);

    if (!feature)
        return (<></>);

    return <Badge color="primary" pill className="preview-tag">preview <span className="preview-action" onClick={() => feature.toggle()}>{feature.isEnabled ? "disable" : "enable"}</span></Badge>
}
